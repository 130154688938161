import React from 'react'
import Input from '../../components/preview-input/input'
import ExtensionsNeededSelection from './extensions-needed-selection'
import FlexGroundspoutSelection from './flex-groundspout-selection'
import InputRow from './input-row'
import LadderSelection from './ladder-selection'
import WalkboardSelection from './walkboard-selection'
import useOrderStore from '../../store/order-store'

const Extensions = () => {

  const { orderData,  setFormData } = useOrderStore();

  const handleInputChange = (rowIndex, colIndex, value) => {
    const newRows = orderData.sizes.map((row, index) =>
      index === rowIndex
        ? { ...row, values: row.values.map((val, i) => (i === colIndex ? value : val)) }
        : row
    );
    setFormData({
      ...orderData,
      sizes:newRows
    })
  };

  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value
    });
  };
  return (
    <div style={{ borderRight: "1px solid black", }}>

 <ExtensionsNeededSelection/>


 <FlexGroundspoutSelection/>



    <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr", alignItems: "center" }}>
      <h4 style={{ fontWeight: "bold" }}></h4>
      <h4></h4>
      <h4 className='k-pl-5'>3</h4>
      <h4 className='k-pl-5'>4</h4>
    </div>

    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", alignItems: "center" }}>
      <h4 className="k-m-0">Size:</h4>
      <h4 className="k-m-0">2 X 3</h4>
      <h4 className="k-m-0">3 X 4</h4>
      <h4 className="k-m-0">Round</h4>
      <h4 className="k-m-0">Round</h4>
    </div>


    <div>
    {orderData.sizes.map((row, rowIndex) => (
          <InputRow
          rowIndex={rowIndex}
          orderData={orderData}
            key={row.label}
            label={row.label}
            values={row.values}
            onInputChange={(colIndex, value) => handleInputChange(rowIndex, colIndex, value)}
          />
        ))}
    </div>



    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2"># of A elbows</h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "100px" }}>
        <Input type='number' value={orderData.AElbows} onChange={handleInputChange2('AElbows')}/>
      </div>
      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
        # of B elbows
      </h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "100px" }}>
        <Input type='number' value={orderData.BElbows} onChange={handleInputChange2('BElbows')}/>
      </div>
    </div>


    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2 ">
        Spout Saver Quantity
      </h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "100px" }}>
        <Input value={orderData.spoutSaverQuantity} onChange={handleInputChange2('spoutSaverQuantity')}/>
      </div>
    </div>


 <LadderSelection/>



    <div className="k-display-flex  k-justify-content-end" style={{ position: "relative", right: "240px" }}>
      <h6 className="k-m-0 k-font-light">
        Review Measurements above
      </h6>
    </div>

<WalkboardSelection/>


    <div className="k-display-flex k-mt-1 k-mb-4">
      <h4 className="k-m-0 k-font-light k-pt-2 ">
        Obstructions:
      </h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "80%" }}>
        <Input value={orderData.obstruction} onChange={handleInputChange2('obstruction')}/>
      </div>
    </div>
  </div>

  )
}

export default Extensions