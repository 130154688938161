import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import DialogSelect from "../../components/dialog/dialog";
import { useRouter } from "../routes/hooks/use-router";
import useResultStore from "../../store/result-store";
import usePreviewStore from "../../store/preview-store";
import useOrderStore from "../../store/order-store";

const DemoCard = ({ isView, leadDetail }) => {
  const router = useRouter();
  const { resultData, setFormData } = useResultStore();
  const handleInputChange2 = (e) => {
    setFormData({
      ...resultData,
      lastPrice: e.target.value,
    });
  };
  console.log(leadDetail?.resulted,"here we go")
  const [visible, setVisible] = useState(false);
  return (
    <div
      className="k-rounded-lg k-elevation-1 k-p-4 k-mb-4 k-cursor-pointer"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <h2 className="k-font-light k-m-0 k-pt-4 k-text-uppercase">Demo Info:</h2>
      <div className="k-ml-2">
        <div className="k-mt-4">
          <Label className="k-font-bold">Last Price Quoted:</Label>
        </div>
        <Input
          type="number"
          value={resultData.lastPrice}
          onChange={handleInputChange2}
          style={{ width: "350px", marginTop: "10px" }}
        />
      </div>
      {leadDetail?.resulted ? (
        <div className="k-display-flex k-justify-content-end k-my-4">
          
          <Button
            onClick={() =>
              router.push(
                leadDetail?.orderSheet
                  ? `/scan-preview/${leadDetail?._id}?hasSheet=true&leadId=${leadDetail?.leadId}`
                  : `/preview/${leadDetail._id}?hasSheet=true&leadId=${leadDetail.leadId}`
              )
            }
            primary={true}
            className="k-button-green k-mr-2"
            size="large"
          >
            {leadDetail?.orderSheet ? "View Order Sheet" : "View Gutter Sheet"}
          </Button>
        </div>
      ) : (
        <div className="k-display-flex k-justify-content-end k-my-4">
          {/* <Button  primary={true} className="k-button-darkBlue k-mr-2" size="large">
          View Quote
        </Button> */}
           <Button
            primary={true}
            className="k-button-darkBlue k-mr-2"
            size="large"
          >
            Build Job
          </Button>
          <Button
            onClick={() => {
              setVisible(true);
            }}
            primary={true}
            className="k-button-darkBlue"
            size="large"
          >
            Add Data
          </Button>
        </div>
      )}
      {visible && (
        <DialogSelect
          visible={visible}
          toggleDialog={() => setVisible(false)}
          leadDetail={leadDetail}
        />
      )}
    </div>
  );
};

export default DemoCard;
