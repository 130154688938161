import { useState } from "react";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function ExistingProtectionSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const existingProtection = orderData.existingProtection;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      existingProtection: value
     });
  };
  return (
    <h4 className="k-m-0 k-font-light k-pt-2">
      Existing Protection:&nbsp;&nbsp;
      <label style={{ marginRight: "8px" }}>
        <input
        disabled={isDisabled}
          type="radio"
          name="existingProtection"
          value="Yes"
          checked={existingProtection === "Yes"}
          onChange={() => handleInputChange("Yes")}
          style={{ marginRight: "4px" }}
        />
        Yes
      </label>
      <label>
        <input
        disabled={isDisabled}
          type="radio"
          name="existingProtection"
          value="No"
          checked={existingProtection === "No"}
          onChange={() => handleInputChange("No")}
          style={{ marginRight: "4px" }}
        />
        No
      </label>
      &nbsp;&nbsp;Type:
    </h4>
  );
}

export default ExistingProtectionSelection;
