import React from 'react';
import styles from './Input.module.scss';
import { useLocation } from 'react-router-dom';

const Input = ({ disable = false, value = '', onChange = () => {} ,type="text"}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');

  const isDisabled = disable || (hasSheet === 'true');
  return (
    <input
    disabled={isDisabled}
      onChange={onChange}
      value={value}
      type={type}
      className={styles.input}
    />
  );
};

export default Input;
