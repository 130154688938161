import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import Input from "../../components/preview-input/input";
import FilterColors from "./filter-colors";
import FilterProfile from "./filter-profile";
import MainDetails from "./main-details";
import OrderProfile from "./order-profile";
import Story from "./story";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DrawingBox from "./drawing-box";
import Extensions from "./extensions";
import useOrderStore from "../../store/order-store";
import axiosInstance,{ endpoints } from "../../utils/axios";
import Success from "../../components/success/success";
import { HOST_API } from "../../config-global";
import { convertErrorsToArray } from "../../utils/formats";
import { toast } from "react-toastify";
import axios from "axios";
import useResultStore from "../../store/result-store";
import { Loader } from "@progress/kendo-react-indicators";

const OrderSheet = () => {
  const { id } = useParams();
  const { orderData, setFormData,reset } = useOrderStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const leadId = queryParams.get('leadId');
  const { resultData } = useResultStore();

  const navigate = useNavigate();
  const [showSuccess,setShowSuccess]=useState(false)
  const [showButton,setShowButton]=useState(false)
  const [loading,setLoading]=useState(true)
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value
    });
  };
  useEffect(() => {
    getSheet();
  }, []);
  const getSheet = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.leads.get_order_detail(leadId)
      );
      if (response.data) {
        console.log(response.data.data);
        let data = response.data.data;
        if (data.customer) {
          setShowButton(false);
          setFormData({
            ...data,
            partial: data.partial ? "Yes" : "No" ,
            existingProtection: data.existingProtection ? "Yes" : "No",
            roof: data.roof ?  "Yes": "No" ,
            extensionsNeeded: data.extensionsNeeded ? "Yes" : "No",
            groundSpoutNeeded: data.groundSpoutNeeded ? "Yes" : "No",
            walkboards: data.walkboards ? "Yes" : "No",            
            roofType:data.roofType !="Flat Roof" &&  data.roofType !="Wood Shake" ? "Other": data.roofType,
            roofTypeValue:data.roofType !="Flat Roof" &&  data.roofType !="Wood Shake" ? data.roofType: "",
            ladders:data.ladders !="8" &&  data.ladders !="10" &&  data.ladders !="24" &&  data.ladders !="28" &&  data.ladders !="32" &&  data.ladders !="40" ? "Other": data.ladders,
            laddersValue:data.ladders !="8" &&  data.ladders !="10" &&  data.ladders !="24" &&  data.ladders !="28" &&  data.ladders !="32" &&  data.ladders !="40"  ? data.ladders: "",
            job:leadId,
            versa_max_quantity:data.versaMaxValue,
            hasSheet: true,
          });
          setLoading(false);
        } else {
          // reset();
          setShowButton(true);
          setFormData({
            ...orderData,
            hasSheet: false,
          });
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };

  const onSubmit = async () => {
    setLoading(true);

    const subdata = {
    ...orderData,
    partial:orderData.partial =="Yes" ? true : false,
    existingProtection:orderData.existingProtection =="Yes" ? true : false,
    roof:orderData.roof =="Yes" ? true : false,
    extensionsNeeded:orderData.extensionsNeeded =="Yes" ? true : false,
    groundSpoutNeeded:orderData.groundSpoutNeeded =="Yes" ? true : false,
    walkboards:orderData.walkboards =="Yes" ? true : false,
    roofType:orderData.roofType =="Other" ? orderData.roofTypeValue : orderData.roofType,
    ladders:orderData.ladders =="Other" ? orderData.laddersValue : orderData.ladders,
    leadId:leadId,
    results:resultData,
    totalFootage:orderData.totalFootage.toString(),
    versaMaxValue:orderData.leafFilterProfile.includes('Versa Max') ? orderData.versa_max_quantity : ''
          };

          delete subdata.images;


    try {
      const response = await axios.post(
        `${HOST_API}${endpoints.leads.post_order}`,
        subdata,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
    setLoading(false);
      console.log("🚀 ~ onSubmit ~ response:", response.data);
      console.log("Submitted Data:", subdata);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate(`/`)
      }, 2000);
    } catch (error) {
    setLoading(false);
      console.error("Error submitting data:", error?.response?.data?.data);
      const errorsArray = convertErrorsToArray(error?.response?.data?.data);

      if (errorsArray.length > 0) {
        errorsArray.forEach((err) => toast.error(err));
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('Internal Server Error');
      }
      
    }
  };

  return (
    <div className="">
      <h2 className="k-py-4 k-font-bold">Preview</h2>
      <div className="k-rounded-lg k-p-4" style={{ background: "#FFFFFF" }}>
        <div
          className=" k-px-8 k-border-solid k-border k-border-black"
          style={{ width: "30%" }}
        >
          <h2 className="k-m-0 k-py-3">LeafFilter Order Sheet</h2>
        </div>
        <div className="k-border-solid k-border k-border-black k-mt-1">
          <MainDetails id={id} />
        </div>
        <div className="k-border-solid k-border k-border-black">
          <OrderProfile />
        </div>
        <div className="k-border-solid k-border k-border-black">
          <FilterProfile />
        </div>
        <div className="k-px-2 k-border-solid k-border k-border-black  k-d-grid k-grid-cols-2">
          <Story />

          <FilterColors />
        </div>

        <div className="k-px-2 k-border-solid k-border k-border-black k-d-grid k-grid-cols-2">
          <Extensions />

          {/* second next section */}
          <DrawingBox />
        </div>
        <div className=" k-px-2 k-border-solid k-border k-border-black">
          <h4 className="k-m-0 k-p-3 k-display-flex k-gap-2">
            <u>Note: </u>
            <Input value={orderData.note} onChange={handleInputChange2('note')}/>
          </h4>
        </div>
      </div>
      {showButton ?    <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
     <Button onClick={() => navigate(`/leads/${id}`)} primary={true} className="k-button k-mr-2" size="large">
         Cancel
        </Button>
     <Button onClick={onSubmit}  primary={true} className="k-button-green k-mr-2" size="large">
         { loading ?  <Loader size='small' type='converging-spinner' style={{color:"white"}}/> : 'Submit'}
        </Button>
     </div>:
     <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
     <Button onClick={() => navigate(`/leads/${id}`)} primary={true} className="k-button k-mr-2" size="large">
         Go Back
        </Button>
     </div>
     }
    {showSuccess && <Success visible={showSuccess} toggleDialog={()=>setShowSuccess(false)}/>}
    </div>
  );
};

export default OrderSheet;
