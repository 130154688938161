import React from "react";
import { AuthConsumer, AuthProvider } from "./auth/context";
import AuthGuard from "./auth/guard/auth-guard";
import Router from "./router/router";
import { BrowserRouter } from "react-router-dom";
import './App.scss'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
      <AuthConsumer>
        <ToastContainer/>
        {/* <AuthGuard> */}
          <Router />
        {/* </AuthGuard> */}
      </AuthConsumer>
      </BrowserRouter>
     
    </AuthProvider>
  );
}

export default App;
