import React, { useState } from "react";
import styles from "./Login.module.scss";
import { Card } from "@progress/kendo-react-layout";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { FaRegEye } from "react-icons/fa";
import { GoEyeClosed } from "react-icons/go";
import { useAuthContext } from '../../../auth/hooks';
import { useRouter } from '../../routes/hooks/use-router';
import { toast } from "react-toastify";
import { Loader, LoaderType } from '@progress/kendo-react-indicators';
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "Please enter a valid email.";

const requiredValidator = (value) => (value ? "" : "This field is required.");
const CustomInput = (fieldRenderProps) => {
  const { validationMessage, visited, submitted, ...others } = fieldRenderProps;

  const [isEmailInput, setIsEmailInput] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setIsEmailInput(/\S+@\S+\.\S+/.test(value)); // Detect if the input matches email format
    fieldRenderProps.onChange(event); // Trigger Kendo's onChange to maintain form state
  };
  return (
    <div className="k-form-field-wrap">
      <Input
        {...others}
        className={styles.k_input}
        placeholder="Username or email address"
        onChange={handleInputChange} // Dynamically check input type
      />
      {validationMessage && visited && (
        <Error>{isEmailInput ? "Please enter a valid email." : "This field is required."}</Error>
      )}
    </div>
  );
};
const PasswordInput = (fieldRenderProps) => {
  const { validationMessage, visited, submitted, ...others } = fieldRenderProps;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="k-form-field-wrap k-pos-relative">
      <Input
        {...others}
        className={styles.k_input}
        type={showPassword ? "text" : "password"}
        placeholder="Password"
      />
      <div className="k-pos-absolute k-bottom-2 k-right-5">
        {showPassword ? (
          <FaRegEye
            className="k-cursor-pointer"
            style={{ fontSize: "1.3rem" }}
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <GoEyeClosed
            className="k-cursor-pointer"
            style={{ fontSize: "1.3rem" }}
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
      {validationMessage && visited && <Error>Please enter the password</Error>}
    </div>
  );
};


const isEmail = (input) => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(input);
};


const Login = () => {
  const { login } = useAuthContext();
  console.log("🚀 ~ Login ~ login:", login)
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  let [disabled, setDisabled] = React.useState(false)

  const handleSubmit = async (dataItem) => {
    setIsSubmitting(true);
    setDisabled(true);

    const apiData = isEmail(dataItem.email)
      ? { email: dataItem.email, password: dataItem.password }
      : { username: dataItem.email, password: dataItem.password };

    try {
      await login?.(apiData); // Pass the correct data structure
      router.push('/');
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
    setIsSubmitting(false);
    setDisabled(false);
  };

  return (
    <div
      className={`${styles.bg_image_container} k-d-flex k-justify-content-center k-align-items-center k-h-screen k-px-5`}
    >
      <Card className={`${styles.login_card}`}>
        <div className={`${styles.login_card_main_container} k-py-25 k-px-6`}>
          <h3 className="k-font-medium">Welcome to LeafFilter</h3>
          <h1 className={styles.heading}>Sign in</h1>
          <span class="k-icon k-font-icon k-i-pencil"></span>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <FormElement
                style={{
                  maxWidth: 650,
                }}
              >
                <fieldset className={"k-form-fieldset"}>
                  <FieldWrapper>
                    <Label className="k-pb-lg k-font-medium">
                      Enter your username or email address&nbsp;
                    </Label>
                    <Field
                      name={"email"}
                      component={CustomInput}
                      validator={(value) =>
                        value
                          ? /\S+@\S+\.\S+/.test(value)
                            ? "" // Email is valid
                            : "" // Username is valid
                          : "This field is required."
                      }
                    />

                  </FieldWrapper>
                  <FieldWrapper>
                    <Label className="k-pb-lg k-font-medium">
                      Enter your Password&nbsp;
                    </Label>
                    <Field
                      name={"password"}
                      component={PasswordInput}
                      labelClassName={"k-form-label"}
                      validator={requiredValidator}
                    />
                  </FieldWrapper>
                </fieldset>
                <div className="k-text-right k-mt-2 k-mb-10">
                  <Label className="k-pb-lg k-font-medium k-cursor-pointer">
                    Forgot Password&nbsp;
                  </Label>
                </div>
                <div className="k-form-buttons">
                  <Button
                  iconClass={disabled ? 'fa fa-spinner fa-spin' : ''}
                    className="k-w-full k-rounded-lg k-h-12 k-elevation-3 k-font-medium k-button-green"
                    disabled={!formRenderProps.allowSubmit || disabled}
                    primary={true}
                    themeColor="primary"
                    size="large"
                  >
                    {isSubmitting ?  <Loader size='small' type='converging-spinner' style={{color:"white"}}/> : 'Sign in'}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </Card>
    </div>
  );
};

export default Login;
