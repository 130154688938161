import { Button } from '@progress/kendo-react-buttons'
import React, { useEffect, useState } from 'react'
import ActionsBar from '../../components/actions-bar/actions-bar'
import SingleLeadTabContent from './single-lead-tabs-content'
import axiosInstance, { endpoints } from '../../utils/axios'
import { useParams } from 'react-router-dom'
import usePreviewStore from '../../store/preview-store'
import useOrderStore from '../../store/order-store'
import useResultStore from '../../store/result-store'

const SingleLeadTabs = () => {
  const { id } = useParams();
  const [leadDetail, setleadDetail] = useState();
  const { orderData, setFormData } = useOrderStore();
  const { previewData, setFormData: setForm } = usePreviewStore();
  const { setFormData: setForm2 } = useResultStore();
  

  
  useEffect(() => {
    getleadDetails();
  }, []);
  const getleadDetails = async () => {
    try {
      const response = await axiosInstance.get(endpoints.leads.get_lead_detail(id)); 
      if (response.data) {
        setleadDetail(response.data.data)
        setForm({...previewData,customer:response.data.data.name,st:response.data.data.state,city:response.data.data.city,job:response.data.data.leadId})
        setFormData({...orderData,customer:response.data.data.name,state:response.data.data.state,city:response.data.data.city,job:response.data.data.leadId})
    if(response.data.data?.results){
      setForm2({
        resultType:response.data.data?.results?.resultType,
        resultReason:response.data.data?.results?.resultReason,
        oneLeg:response.data.data?.results?.oneLeg,
        lastPrice:response.data.data?.results?.lastPrice
      })
    }
      }
    } catch (error) {
      console.error('Error fetching :', error);
    }
  };
  return (
    <div className='k-mt-2'>
    <Button
          primary={true}
          className=""
          size="large"
        >
          Yesterday
        </Button>
        <Button
          primary={true}
          className="k-button-red k-mx-2"
          size="large"
        >
          Today
        </Button>
        <Button
          primary={true}
          className=""
          size="large"
        >
          Tomorrow
        </Button>
        <div className='k-mt-10'>
            <ActionsBar/>
        </div>
        <div className='k-mt-2'>
        <SingleLeadTabContent leadDetail={leadDetail} />
        </div>
  </div>
  )
}

export default SingleLeadTabs