export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

// ----------------------------------------------------------------------

export function flattenArray(list, key = 'children') {
    let children = [];
  
    const flatten = list?.map((item) => {
      if (item[key] && item[key].length) {
        children = [...children, ...item[key]];
      }
      return item;
    });
  
    return flatten?.concat(children.length ? flattenArray(children, key) : children);
  }
  export function convertErrorsToArray(data) {
    if (!data) return [];
    return Object.values(data);
  }
export const colorList=[
  "WHITE",
  "CLAY",
  "N/A",
  "BLACK",
  "LINEN",
  "ANTIQUE IVORY",
  "COCOA BROWN",
  "COLONIAL GRAY",
  "COPPER",
  "CREAM",
  "DOVE GRAY",
  "EGGSHELL",
  "FORREST GREEN",
  "PEARL GRAY",
  "SCOTCH RED",
  "TERRATONE",
  "TRADITIONAL BLUE",
  "TUXEDO GRAY",
  "Bronze",
]