import { useState } from "react";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function WalkboardSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const walkboardNeeded = orderData.walkboards;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      walkboards: value
     });
  };
  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">
        Walkboards:&nbsp;&nbsp;&nbsp;
        <label style={{ marginRight: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="walkboardNeeded"
            value="Yes"
            checked={walkboardNeeded === "Yes"}
            onChange={() => handleInputChange("Yes")}
            style={{ marginRight: "4px" }}
          />
          Yes
        </label>
        <label style={{ marginLeft: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="walkboardNeeded"
            value="No"
            checked={walkboardNeeded === "No"}
            onChange={() => handleInputChange("No")}
            style={{ marginRight: "4px" }}
          />
          No
        </label>
      </h4>
    </div>
  );
}

export default WalkboardSelection;
