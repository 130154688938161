import { useState } from "react";
import usePreviewStore from "../../store/preview-store";
import { useLocation } from "react-router-dom";

function DownspoutSelection() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const { previewData,  setFormData } = usePreviewStore();
  const selectedDownspout = previewData.downspout;
  const handleInputChange = (value) => {
    setFormData({ 
      ...previewData,
      downspout: value
     });
  };
  const isDisabled =(hasSheet === 'true');
  return (
    <div className="k-mt-2 k-display-flex k-gap-4">
      <h4 className="k-m-0 k-font-light k-pt-4">Downspout:</h4>
      
      <div className="k-display-flex k-gap-2 k-mt-2">
        <label className="k-m-0 k-font-light k-pt-2 k-mr-4">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="downspout"
            value="2x3"
            checked={selectedDownspout === "2x3"}
            onChange={() => handleInputChange("2x3")}
          />
          2 x 3
        </label>

        <label className="k-m-0 k-font-light k-pt-2 k-mr-4">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="downspout"
            value="3x4"
            checked={selectedDownspout === "3x4"}
            onChange={() => handleInputChange("3x4")}
          />
          3 x 4
        </label>

        <label className="k-m-0 k-font-light k-pt-2 k-mr-4">
          <input
          disabled={isDisabled}
            type="radio"
            style={{marginRight:"10px"}}
            name="downspout"
            value="3x3"
            checked={selectedDownspout === "3x3"}
            onChange={() => handleInputChange("3x3")}
          />
          3 x 3
        </label>

        <label className="k-m-0 k-font-light k-pt-2 k-mr-4">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="downspout"
            value="3Round"
            checked={selectedDownspout === "3Round"}
            onChange={() => handleInputChange("3Round")}
          />
          3 Round
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="downspout"
            value="4Round"
            checked={selectedDownspout === "4Round"}
            onChange={() => handleInputChange("4Round")}
          />
          4 Round
        </label>
      </div>
    </div>
  );
}

export default DownspoutSelection;
