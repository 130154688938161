import React from "react";
import { useRouter } from "../routes/hooks/use-router";
import { formatDate } from "../../utils/formats";

const HomeTabContentCard = ({ item }) => {
  const router = useRouter();
  //date foramte

  return (
    <div
      onClick={() =>
        router.push(`/leads/${item._id}`)
      }
      className="k-rounded-lg k-elevation-1 k-p-4 k-mb-4 k-cursor-pointer"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <div>
        <h2 className="k-text-uppercase">{formatDate(item.time)}</h2>
        <h4 className="k-text-uppercase">Id: {item._id}</h4>
        <h4 className="k-text-uppercase">Code: {item.code}</h4>
      </div>
      <div className="k-text-end">
        {/* <h4
          style={{ color: "#FF0000" }}
          className="k-m-0 k-pb-2 k-text-uppercase"
        >
          {item.status}
        </h4> */}
        <h1 className="k-m-0 k-pb-2 k-font-normal k-text-uppercase">
          {item.name}
        </h1>
        <h2
          style={{
            color:"#FF0000",
          }}
          className="k-m-0 k-pb-2 k-text-uppercase"
        >
          Not Resulted
        </h2>
      </div>
    </div>
  );
};

export default HomeTabContentCard;
