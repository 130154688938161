import { useState } from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";
import { useLocation } from "react-router-dom";
import { colorList } from "../../utils/formats";

function Painted() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const { previewData, setFormData } = usePreviewStore();
  const selectedSize = previewData.fascia_other;
  const handleInputChange = (value) => {
    setFormData({
      ...previewData,
      fascia_other: value,
    });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };
  return (
    <div className="k-d-flex k-grid-cols-1">
      {/* <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-16">
        Size:
      </h4> */}

      <div className="k-d-flex k-gap-2">
        <label className="k-font-light k-m-0">
          <input
          disabled={isDisabled}
            style={{ marginRight: "10px" }}
            type="radio"
            name="paint-type"
            value="Painted"
            checked={selectedSize === "Painted"}
            onChange={() => handleInputChange("Painted")}
          />
         Painted  
        </label>

        <label className="k-font-light  k-m-0">
          <input
          disabled={isDisabled}
            style={{ marginRight: "10px" }}
            type="radio"
            name="paint-type"
            value="Metal Wrapped "
            checked={selectedSize === "Metal Wrapped "}
            onChange={() => handleInputChange("Metal Wrapped ")}
          />
          Metal Wrapped 
        </label>
     { selectedSize === "Metal Wrapped " &&  <div className="k-d-flex k-m-0 k-font-light k-mt-0 k-pl-2">
      <label style={{width:"fit-content"}} className="k-pr-4">Metal Wrapped Color</label>
          <select value={previewData.metal_wrapped_color} onChange={handleInputChange2('metal_wrapped_color')}  disabled={isDisabled} style={{width:"200px",height:"30px",borderRadius:"5px"}} id="colorSelect" name="soffit_color">
          {
          colorList.map((col)=>(
            <option value={col}>{col}</option>
          ))
           }
        </select>
  
          </div>}

        <label className="k-font-light  k-m-0">
          <input
          disabled={isDisabled}
            style={{ marginRight: "10px" }}
            type="radio"
            name="paint-type"
            value="Other"
            checked={selectedSize === "Other"}
            onChange={() => handleInputChange("Other")}
          />
          Other
        </label>

        {selectedSize === "Other" && (
          <div className="k-d-flex k-m-0 k-w-30 k-font-light k-mt-0">
            <Input
              value={previewData.fascia_other_value}
              onChange={handleInputChange2("fascia_other_value")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Painted;
