export const orderValues = {
  customer: "",
  city: "",
  state: "",
  job: "",
  gutterProfile: "K-style",
  leafFilterProfile: [],
  versa_max_quantity:"",
  measurements: [
    {
      label: "Left Side of Home",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Right Side of Home",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Front of Home",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Rear of Home",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Garage",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Other",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Total",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
  ],
  color: "White",
  leafFilterColor: "White",
  existingGutterColor: "",
  gutterOpeningWidth: "4",
  totalFootage: "",
  partial: "Yes",
  csr: false,
  csr_value: '',
  insideCorners: 0,
  outsideCorners: 0,
  existingProtection: "Yes",
  existingProtectionType: "screen",
  mountingType: "Hidden Hangers",
  // mountingTypeValue: "",
  mountMethod : "Straps",
  mountingLength: "",
  roof: "Yes",
  // roofValue:"",
  roofColor: "red",
  roofMaterial: "Asphalt Shingle",
  // roofMaterialValue: "",
  roofType: "Flat Roof",
  roofTypeValue: "",
  extensionsNeeded: "Yes",
  extensionFootage:'',
  groundSpoutNeeded:'Yes',
  units: 0,
  sizes: [
    { label: "1", values: ["", "", "", ""] },
    { label: "2", values: ["", "", "", ""] },
    { label: "3", values: ["", "", "", ""] },
    { label: "4", values: ["", "", "", ""] },
    { label: "5", values: ["", "", "", ""] },
  ],
  AElbows: 0,
  BElbows: 0,
  spoutSaverQuantity: "",
  ladders: "8",
  laddersValue: "",
  walkboards: "Yes",
  obstruction: "",
  note: "",
  files:[],
  hasSheet: false,
};
