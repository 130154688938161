import React, { useState } from 'react';
import core from '../../assets/images/core.png';
import versa from '../../assets/images/versa.png';
import modular from '../../assets/images/modular.png';
import { useLocation } from 'react-router-dom';
import useOrderStore from '../../store/order-store';

const FilterProfile = () => {
  const { orderData, setFormData } = useOrderStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled = hasSheet === 'true';
  const selectedFilterProfiles = orderData.leafFilterProfile || [];  // Defaults to an empty array if no profiles are selected
  const handleInputChange = (value) => {
    // If the filter is disabled, do not make changes
    if (isDisabled) {
      return;
    }

    // If the value is already in the array, remove it; otherwise, add it to the array
    const updatedProfiles = selectedFilterProfiles.includes(value)
      ? selectedFilterProfiles.filter(profile => profile !== value) // Remove the value if already selected
      : [...selectedFilterProfiles, value]; // Add the value if not already selected

    // Update the form data with the new array of profiles
    setFormData({
      ...orderData,
      leafFilterProfile: updatedProfiles
    });
  };
  
  return (
    <div className='k-d-flex'>
      <div className='k-d-flex k-align-items-center' style={{width:"240px"}}>
          <h2 className='k-m-0 k-pt-1 k-px-2'>LealFilter Profile</h2>
      </div>
    <div className='k-d-grid k-grid-cols-4 k-text-center k-gap-0 k-pt-4 k-pb-1 k-px-2' style={{width:"100%"}}>
      <div className='k-display-flex k-justify-content-between k-align-items-center k-gap-2'>

      {/* Core Profile */}
      <div
        className='k-display-flex k-justify-content-center k-align-items-center k-gap-2'
        // onClick={() => handleInputChange("Core")}
      >
         <input
            type="checkbox"
            name="leafFilterProfile"
            value="Core"
            disabled={isDisabled}
            checked={selectedFilterProfiles.includes('Core')}
            onChange={() => handleInputChange('Core')}
            className="k-mr-2"
          />
        <h2>Core</h2>
        <div className='k-pos-relative k-top-1 k-left-10 k-mb-3'>
          <img alt='core' src={core} />
        </div>
      </div>
      </div>


      {/* Modular Profile */}
      <div
        className='k-display-flex k-justify-content-center k-align-items-center k-gap-3'
        // onClick={() => handleInputChange("Modular")}
      >
             <input
            type="checkbox"
            name="leafFilterProfile"
            value="Modular"
            disabled={isDisabled}
            checked={selectedFilterProfiles.includes('Modular')}
            onChange={() => handleInputChange('Modular')}
            className="k-mr-2"
          />
        <h2 className='k-m-0'>Modular</h2>
        <div className='k-pos-relative k-top-1 k-left-10 k-mb-3'>
          <img alt='modular' src={modular} />
        </div>
      </div>

      {/* Versa Profile */}
      <div
        className='k-display-flex k-justify-content-center k-align-items-center k-gap-3'
        // onClick={() => handleInputChange("Versa")}
      >
         <input
            type="checkbox"
            name="leafFilterProfile"
            value="Versa"
            disabled={isDisabled}
            checked={selectedFilterProfiles.includes('Versa')}
            onChange={() => handleInputChange('Versa')}
            className="k-mr-2"
          />
        <h2 className='k-m-0'>Versa</h2>
        <div className='k-pos-relative k-top-1 k-left-10 k-mb-3'>
          <img alt='versa' src={versa} />
        </div>
      </div>
      <div
        className='k-display-flex k-justify-content-center k-align-items-center k-gap-3'
        // onClick={() => handleInputChange("Versa")}
      >
         <input
            type="checkbox"
            name="leafFilterProfile"
            value="Versa Max"
            disabled={isDisabled}
            checked={selectedFilterProfiles.includes('Versa Max')}
            onChange={() => handleInputChange('Versa Max')}
            className="k-mr-2"
          />
        <h2 className='k-m-0'>Versa Max</h2>
        <div className='k-pos-relative k-top-1 k-left-10 k-mb-3'>
          <img alt='versa' src={versa} />
        </div>
      </div>
    </div>
    
    </div>
  
  );
};

export default FilterProfile;
