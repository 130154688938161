import { useCallback, useEffect } from 'react';
// routes

import { useAuthContext } from '../hooks';
import { useRouter } from '../../pages/routes/hooks/use-router';
import { useSearchParams } from '../../pages/routes/hooks/use-search-params';
//


// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo') || "/";

  const { authenticated } = useAuthContext();

  const check = useCallback(() => {
    if (authenticated) {
      router.replace(returnTo);
    }
  }, [authenticated, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}

