import { create } from 'zustand';
import { resultValues } from './utils/result-values';

const useResultStore = create((set) => ({
  resultData: { ...resultValues },
  setFormData: (newFormData) => 
    set((state) => ({
      resultData: { ...state.previewData, ...newFormData },
    })),
  reset: () =>
    set(() => ({
      resultData: JSON.parse(JSON.stringify(resultValues)),
    })),
}));

export default useResultStore;
