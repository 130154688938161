import React, { useEffect } from 'react'
import SingleLeadToolBar from './single-lead-toolbar'
import SingleLeadTabs from './single-lead-tabs'
import ActionsBar from '../../components/actions-bar/actions-bar'
import usePreviewStore from '../../store/preview-store'
import useOrderStore from '../../store/order-store'
import useResultStore from '../../store/result-store'

const SingleLead = () => {
  const { reset: resetPreview } = usePreviewStore();
  const { reset: resetOrder } = useOrderStore();
  const { reset: resetResult } = useResultStore();
useEffect(() => {
  // resetPreview()
  // resetOrder()
  // resetResult()
}, [])

  return (
   <>
   <SingleLeadToolBar/>
   <SingleLeadTabs/>
   <div className='k-pt-2 k-pb-1 k-mb-1' style={{backgroundColor:"#FFFFFF"}}>
    <ActionsBar/>
    </div>
   </>
  )
}

export default SingleLead