import React, { useEffect, useState } from "react";
import MainDetails from "./main-details";
import Input from "../../components/preview-input/input";
import Drawing from "./drawing";
import MeasurementsLeft from "./measurements-left";
import MeasurementsRight from "./measurements-right";
import Fascia from "./fascia";
import Soffit from "./soffit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import Success from "../../components/success/success";
import usePreviewStore from "../../store/preview-store";
import axiosInstance, { endpoints } from "../../utils/axios";
import axios from "axios";
import { HOST_API } from "../../config-global";
import { toast } from "react-toastify";
import { convertErrorsToArray } from "../../utils/formats";
import useResultStore from "../../store/result-store";
import { Loader } from "@progress/kendo-react-indicators";

const Preview = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { previewData, setFormData, reset } = usePreviewStore();
  
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const leadId = queryParams.get("leadId");
  const { resultData } = useResultStore();
 console.log(previewData,"here we go preview Data")
  useEffect(() => {
    // reset();
    getSheet();
  }, []);
  const getSheet = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.leads.get_sheet_detail(leadId)
      );
      if (response.data) {
        console.log(response.data.data);
        let data = response.data.data;
        if (data.customer) {
 console.log(true,"here we go preview Data")
          const values = {
            customer: data.customer || "",
            city: data.city || "",
            st: data.street || "",
            measurements: data.measurements,
            roof_type: data.roof.roofType || "",
            gutter_type: data.roof.gutterType || "",
            color: data.roof.gutterColor || "",
            gutter_size: data.roof.gutterSize || "",
            total_gutter_footage: data.roof.totalGutterFootage || "",
            downspout: data.roof.downSpout || "",
            downspout_color: data.roof.downSpoutColor || "",
            aelbows: data.roof.downSpoutFootage.ofAElbows || "",
            belbows: data.roof.downSpoutFootage.ofBElbows || "",
            roundElbows: data.roof.downSpoutFootage.ofRoundElbows || "",
            offsetElbows: data.roof.downSpoutFootage.ofOffsetElbows || "",
            downspout_footage: data.roof.downSpoutFootage.totalFootage || "",
            outside: data?.misc?.ofMitersOutside || "",
            inside: data?.misc?.ofMitersInside || "",
            rights: data?.misc?.ofEndcapsRights || "",
            lefts: data?.misc?.ofEndcapsLefts || "",
            savers: data?.misc?.ofSproutSavers || "",
            straps: data?.misc?.ofRoofStraps || "",
            wedges: data?.misc?.ofwedges || "",
            partial_install: data?.misc?.partialInstall ? "Yes" : "No",
            fascia_replacement: data?.fasciaReplacement?.type || "",
            fascia_total_footage: data?.fasciaReplacement?.totalFootage || "",
            fascia_size:
              data?.fasciaReplacement?.size != "1x4" &&
              data?.fasciaReplacement?.size != "1x6" &&
              data?.fasciaReplacement?.size != "1x8" &&
              data?.fasciaReplacement?.size != "1x10"
                ? "Other"
                : data?.fasciaReplacement?.size,
            fascia_other_size: data?.fasciaReplacement?.size,
            fascia_type: data?.fasciaReplacement?.productType || "",
            soffit_other_value: data?.fasciaReplacement?.productType !='Painted' && data?.fasciaReplacement?.productType !='Metal Wrapped ' ? data?.fasciaReplacement?.productType : "",
            fascia_other: data?.fasciaReplacement?.productDetail || "",
            metal_wrapped_color: data?.metalWrappedColor || "WHITE",
            fascia_wrap: data?.fasciaReplacement?.totalFtOfNewWrap || "",
            wrapColor: "White",
            soffit_replacement: data?.soffitReplacement?.replacementType || "",
            soffit_other: data?.soffitReplacement?.soffitType || "",
            soffit_other_value: data?.soffitReplacement?.soffitType !='Wood' && data?.soffitReplacement?.soffitType !='Vinyl' ? data?.soffitReplacement?.soffitType : "",
            soffit_color: data?.soffitReplacement?.soffitColor || "",
            soffit_total_footage: data?.soffitReplacement?.totalFootage || "",
            porch_ceiling: data?.porchCeiling,
            images:data.images
          };
          setFormData(values);
          setShowButton(false);
          setLoading(false);
        } else {
          setShowButton(true);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };
  const onSubmit = async () => {
    setLoading(true);
    const subdata = {
      customer: previewData.customer || "",
      city: previewData.city || "",
      street: previewData.st || "",
      leadId: leadId,
      // leadId: Math.random().toString(),
      measurements: previewData.measurements,
      roof: {
        roofType: previewData.roof_type || "",
        gutterType: previewData.gutter_type || "",
        gutterColor: previewData.color || "",
        gutterSize: previewData.gutter_size || "",
        totalGutterFootage: previewData.total_gutter_footage || 0,
        downSpout: previewData.downspout || "",
        downSpoutColor: previewData.downspout_color || "",

        downSpoutFootage: {
          ofAElbows: previewData.aelbows || "",
          ofBElbows: previewData.belbows || "",
          ofRoundElbows: previewData.roundElbows || "",
          ofOffsetElbows: previewData.offsetElbows || "",
          totalFootage: previewData.downspout_footage || 0,
        },
      },

      misc: {
        ofMitersOutside: previewData.outside || "",
        ofMitersInside: previewData.inside || "",
        ofEndcapsRights: previewData.rights || "",
        ofEndcapsLefts: previewData.lefts || "",
        ofSproutSavers: previewData.savers || "",
        ofRoofStraps: previewData.straps || "",
        ofwedges: previewData.wedges || "",
        partialInstall: previewData.partial_install == "Yes" ? true : false,
      },

      fasciaReplacement: {
        type: previewData.fascia_replacement || "",
        totalFootage: previewData.fascia_total_footage || 0,
        size:
          previewData.fascia_size === "Other"
            ? previewData.fascia_other_size || ""
            : previewData.fascia_size || "",
        productType: previewData.fascia_type=="Other" ? previewData.fascia_other_value : previewData.fascia_type,
        productDetail: previewData.fascia_other || "",
        totalFtOfNewWrap: previewData.fascia_wrap || "",
        wrapColor: "White",
      },

      soffitReplacement: {
        replacementType: previewData.soffit_replacement || "",
        soffitType: previewData.soffit_other=='Other' ? previewData.soffit_other_value : previewData.soffit_other,
        soffitColor: previewData.soffit_color || "",
        totalFootage: previewData.soffit_total_footage || 0,
      },
      metalWrappedColor: previewData.fascia_other=='Metal Wrapped' ? previewData.metal_wrapped_color : "",
      porchCeiling: previewData?.porch_ceiling,
    };
    const formData = new FormData();

    // Flattening and appending each key-value pair
    formData.append("customer", subdata.customer);
    formData.append("city", subdata.city);
    formData.append("street", subdata.street);
    formData.append("leadId", subdata.leadId);

    // Roof data
    formData.append("roof.roofType", subdata.roof.roofType);
    formData.append("roof.gutterType", subdata.roof.gutterType);
    formData.append("roof.gutterColor", subdata.roof.gutterColor);
    formData.append("roof.gutterSize", subdata.roof.gutterSize);
    formData.append("roof.totalGutterFootage", subdata.roof.totalGutterFootage);
    formData.append("roof.downSpout", subdata.roof.downSpout);
    formData.append("roof.downSpoutColor", subdata.roof.downSpoutColor);

    formData.append(
      "roof.downSpoutFootage.ofAElbows",
      subdata.roof.downSpoutFootage.ofAElbows
    );
    formData.append(
      "roof.downSpoutFootage.ofBElbows",
      subdata.roof.downSpoutFootage.ofBElbows
    );
    formData.append(
      "roof.downSpoutFootage.ofRoundElbows",
      subdata.roof.downSpoutFootage.ofRoundElbows
    );
    formData.append(
      "roof.downSpoutFootage.ofOffsetElbows",
      subdata.roof.downSpoutFootage.ofOffsetElbows
    );
    formData.append(
      "roof.downSpoutFootage.totalFootage",
      subdata.roof.downSpoutFootage.totalFootage
    );

    // Misc data
    formData.append("misc.ofMitersOutside", subdata.misc.ofMitersOutside);
    formData.append("misc.ofMitersInside", subdata.misc.ofMitersInside);
    formData.append("misc.ofEndcapsRights", subdata.misc.ofEndcapsRights);
    formData.append("misc.ofEndcapsLefts", subdata.misc.ofEndcapsLefts);
    formData.append("misc.ofSproutSavers", subdata.misc.ofSproutSavers);
    formData.append("misc.ofRoofStraps", subdata.misc.ofRoofStraps);
    formData.append("misc.ofwedges", subdata.misc.ofwedges);
    formData.append("misc.partialInstall", subdata.misc.partialInstall);
    formData.append("metalWrappedColor", previewData.fascia_other=="Metal Wrapped " ? previewData.metal_wrapped_color : "");
    // Fascia Replacement
    formData.append("fasciaReplacement.type", subdata.fasciaReplacement.type);
    Object.entries(resultData).forEach(([key, value]) => {
      formData.append(`results[${key}]`, value);
  });
    formData.append(
      "fasciaReplacement.totalFootage",
      subdata.fasciaReplacement.totalFootage
    );
    formData.append("fasciaReplacement.size", subdata.fasciaReplacement.size);
    formData.append(
      "fasciaReplacement.productType",
      subdata.fasciaReplacement.productType
    );
    formData.append(
      "fasciaReplacement.productDetail",
      subdata.fasciaReplacement.productDetail
    );
    formData.append(
      "fasciaReplacement.totalFtOfNewWrap",
      subdata.fasciaReplacement.totalFtOfNewWrap
    );
    formData.append(
      "fasciaReplacement.wrapColor",
      subdata.fasciaReplacement.wrapColor
    );

    // Soffit Replacement
    formData.append(
      "soffitReplacement.replacementType",
      subdata.soffitReplacement.replacementType
    );
    formData.append(
      "soffitReplacement.soffitType",
      subdata.soffitReplacement.soffitType
    );
    formData.append(
      "soffitReplacement.soffitColor",
      subdata.soffitReplacement.soffitColor
    );
    formData.append(
      "soffitReplacement.totalFootage",
      subdata.soffitReplacement.totalFootage
    );

    if (Array.isArray(subdata.porchCeiling)) {
      subdata.porchCeiling.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`porchCeiling[${index}][${key}]`, item[key]);
        });
      });
    }

    if (Array.isArray(subdata.measurements)) {
      subdata.measurements.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`measurements[${index}][${key}]`, item[key]);
        });
      });
    }

    if (previewData.files && Array.isArray(previewData.files)) {
      previewData.files.forEach((file, index) => {
        console.log("file=====>", file);

        formData.append(`files`, file);
      });
    }

    try {
      const response = await axios.post(
        `${HOST_API}${endpoints.leads.post_lead_preview}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    setLoading(false);
      console.log("🚀 ~ onSubmit ~ response:", response.data);
      console.log("Submitted Data:", subdata);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate(`/`);
      }, 2000);
    } catch (error) {
    setLoading(false);
      console.error("Error submitting data:", error?.response?.data?.data);
      const errorsArray = convertErrorsToArray(error?.response?.data?.data);

      if (errorsArray.length > 0) {
        errorsArray.forEach((err) => toast.error(err));
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("Internal Server Error");
      }
    }
  };

  return (
    <div className="">
      <h2 className="k-py-4 k-font-bold">Preview</h2>
      <div className="k-rounded-lg k-p-4" style={{ background: "#FFFFFF" }}>
        <div
          className=" k-px-8 k-border-solid k-border k-border-black"
          style={{ width: "60%" }}
        >
          <h2 className="k-text-right k-m-0 k-py-3">
            Gutter / Fascia / Soffit Prep Form
          </h2>
        </div>
        <div className="k-border-solid k-border k-border-black k-mt-1">
          <MainDetails id={id} />
        </div>
        <div className=" k-px-4 k-border-solid k-border k-border-black k-mt-1">
          <h2 className="k-m-0 k-py-3">
            Measurements (list each run separate and round up to the nearest
            foot)
          </h2>
        </div>

        <div className="k-px-2 k-border-solid k-border k-border-black k-mt-1 k-d-grid k-grid-cols-2">
          <MeasurementsLeft />

          <MeasurementsRight />
        </div>

        <Fascia />

        <Soffit />
      </div>
      {showButton ? (
        <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
          <Button
            onClick={() => navigate(`/leads/${id}`)}
            primary={true}
            className="k-button k-mr-2"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            primary={true}
            className="k-button-green k-mr-2"
            size="large"
          >
          { loading ?  <Loader size='small' type='converging-spinner' style={{color:"white"}}/> : 'Submit'}
          </Button>
        </div>
      ) : (
        <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
          <Button
            onClick={() => navigate(`/leads/${id}`)}
            primary={true}
            className="k-button k-mr-2"
            size="large"
          >
            Go Back
          </Button>
        </div>
      )}
      {showSuccess && (
        <Success
          visible={showSuccess}
          toggleDialog={() => setShowSuccess(false)}
        />
      )}
    </div>
  );
};

export default Preview;
