import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils.js';
import axios , { endpoints } from '../../utils/axios.js';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);
      if (accessToken) {
        setSession(accessToken);

        // const response = await axios.get(endpoints.auth.me);

        // const { data } = response.data;
        dispatch({
          type: 'INITIAL',
          payload: {
            user: accessToken,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // // LOGIN
  // const login = useCallback(async (apiData) => {
    // const apiData = {
    //   email,
    //   password,
    // };
  //   const response = await axios.post(endpoints.auth.login, apiData);
  //   const { data } = response.data;
  //   setSession(data?.token);
  //   const makeData = {
  //     ...data?.userDetails,
  //     permissions: data?.permissions,
  //   };
  //   dispatch({
  //     type: 'LOGIN',
  //     payload: {
  //       user: makeData,
  //     },
  //   });
  // }, []);

  const login = useCallback(async (apiData) => {
    const response = await axios.post(endpoints.auth.login, apiData);
    const  data  = response.data;
  console.log("🚀 ~ login ~ data", data)
    setSession(data?.token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: data?.token,
      },
    });
  }, []);


  // // REGISTER
  // const register = useCallback(async (email, password, firstName, lastName) => {
  //   const data = {
  //     email,
  //     password,
  //     firstName,
  //     lastName,
  //   };

  //   const response = await axios.post(endpoints.auth.register, data);

  //   const { accessToken, user } = response.data;

  //   localStorage.setItem(STORAGE_KEY, accessToken);

  //   dispatch({
  //     type: 'REGISTER',
  //     payload: {
  //       user,
  //     },
  //   });
  // }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      // //
      login,
      // register,
      logout,
    }),
    [state.user, status]
    // [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
