import React, { useEffect, useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import ReactDOM from "react-dom";
import { useLottie } from "lottie-react";
import groovySuccssAnimation from "./success.json";
function Modal({ isOpen, onClose, children }) {
  const dialogRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dialogRef.current &&
      !dialogRef.current.contains(event.target) &&
      isOpen
    ) {
      onClose(false);
    }
  };

  const handleKeydown = (ev) => {
    if (ev.key === "Escape") {
      onClose(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeydown);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeydown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [isOpen]);

  let portalRoot = document.getElementById("modal");
  if (!portalRoot) {
    portalRoot = document.createElement("div");
    portalRoot.setAttribute("id", "modal");
    document.body.appendChild(portalRoot);
  }
  const displayClass = isOpen ? "show" : "hide";

  return ReactDOM.createPortal(
    <div id="modal-comp" className={displayClass} isOpen={isOpen}>
      <div className="dialog" ref={dialogRef} tabIndex={1} style={{width:"35rem"}}>
        {children}
      </div>
    </div>,
    portalRoot
  );
}

function Close(props) {
  return <IoCloseSharp className="close-svg" {...props} />;
}

const Success = ({ visible, toggleDialog }) => {
    const options = {
        animationData: groovySuccssAnimation,
        loop: false
      };
    
      const { View } = useLottie(options);
    
  return (
    <div className="container-dailog">
      <Modal isOpen={visible} onClose={() => toggleDialog()}>
        <div className="k-pt-5 k-pb-3">
        <div style={{ width: 300, margin: "0 auto" }}>{View}</div>
        <h1 className="k-text-center" style={{color:"rgb(58, 188, 94)"}}>Successfully</h1>
        </div>
      </Modal>
    </div>
  );
};

export default Success;
