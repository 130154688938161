import React, { useEffect } from "react";
import HomeToolBar from "./home-toolbar";
import HomeTabs from "./home-tabs";
import usePreviewStore from "../../store/preview-store";
import useOrderStore from "../../store/order-store";
import useResultStore from "../../store/result-store";
const Home = () => {
  const { reset: resetPreview } = usePreviewStore();
  const { reset: resetOrder } = useOrderStore();
  const { reset: resetResult } = useResultStore();
  useEffect(() => {
    resetPreview()
    resetOrder()
    resetResult()
  }, [])
  
  return (
    <div>
      <HomeToolBar />
      <HomeTabs/>
    </div>
  );
};

export default Home;
