import { useState } from "react";
import Input from "../../components/preview-input/input";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function RoofTypeSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const selectedRoofType = orderData.roofType;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      roofType: value
     });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value
    });
  };
  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">

      </h4>

   
    </div>
  );
}

export default RoofTypeSelection;
