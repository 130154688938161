import { create } from 'zustand';
import { orderValues } from './utils/order-values';

const useOrderStore = create((set) => ({
  orderData: { ...orderValues },
  setFormData: (newFormData) => 
    set((state) => ({
      orderData: { ...state.previewData, ...newFormData },
    })),
  reset: () =>
    set(() => ({
      orderData: JSON.parse(JSON.stringify(orderValues)),
    })),
}));

export default useOrderStore;
