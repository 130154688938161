import { useState } from "react";
import Input from "../../components/preview-input/input";
import useOrderStore from '../../store/order-store'
import { useLocation } from "react-router-dom";

function GutterOpeningWidthSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const selectedWidth = orderData.gutterOpeningWidth;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      gutterOpeningWidth: value
     });
  };
  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">Gutter Opening Width:</h4>
      
      <div className="k-display-flex k-gap-3 k-ml-2">
        <label className="k-m-0 k-font-light k-pt-2">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="4"
            checked={selectedWidth === "4"}
            onChange={() => handleInputChange("4")}
          />
          4
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="4.5"
            checked={selectedWidth === "4.5"}
            onChange={() => handleInputChange("4.5")}
          />
          4.5
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="5"
            checked={selectedWidth === "5"}
            onChange={() => handleInputChange("5")}
          />
          5
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="5.5"
            checked={selectedWidth === "5.5"}
            onChange={() => handleInputChange("5.5")}
          />
          5.5
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="6"
            checked={selectedWidth === "6"}
            onChange={() => handleInputChange("6")}
          />
          6
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="7"
            checked={selectedWidth === "7"}
            onChange={() => handleInputChange("7")}
          />
          7
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="gutterWidth"
            value="8"
            checked={selectedWidth === "8"}
            onChange={() => handleInputChange("8")}
          />
          8
        </label>
      </div>

      <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "100px" }}>
        <Input />
      </div>
    </div>
  );
}

export default GutterOpeningWidthSelection;
