import React from "react";
import Input from "../../components/preview-input/input";

const InputRow = ({ label, values, onInputChange,rowIndex}) => (
  <div
    style={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
      alignItems: "center",
    }}
  >
    <h4 className="k-mt-1">{rowIndex+1})</h4>
    {values.map((value, index) => (
      <div key={index} className="k-pos-relative k-top-1" style={{ width: "100px" }}>
        <Input
        type="number"
          value={value}
          onChange={(e) => onInputChange(index, e.target.value)}
        />
      </div>
    ))}
  </div>
);

export default InputRow;
