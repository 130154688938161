import { useState } from "react";
import Input from "../../components/preview-input/input";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function FastenerSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const selectedFastener = orderData.mountingType;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      mountingType: value
     });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value
    });
  };
  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">Fastener Type</h4>
      {/* <div className="k-pos-relative k-top-1" style={{ width: "100px" }}>
        <Input value={orderData.mountingTypeValue} onChange={handleInputChange2('mountingTypeValue')}/>
      </div> */}
      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
        <label>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="Hidden Hangers"
            checked={selectedFastener === "Hidden Hangers"}
            onChange={() => handleInputChange("Hidden Hangers")}
            style={{ marginRight: "10px" }}
          />
          Hidden Hangers
        </label>
        
        <label>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="Spikes"
            checked={selectedFastener === "Spikes"}
            onChange={() => handleInputChange("Spikes")}
            style={{ marginRight: "10px" }}
          />
          Spikes
        </label>
        
        <label style={{ margin: "0 8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="T Straps"
            checked={selectedFastener === "T Straps"}
            onChange={() => handleInputChange("T Straps")}
            style={{ marginRight: "10px" }}
          />
          T Straps
        </label>
        
        <label style={{ margin: "0 8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="Wedges"
            checked={selectedFastener === "Wedges"}
            onChange={() => handleInputChange("Wedges")}
            style={{ marginRight: "10px" }}
          />
          Wedges
        </label>
        
        <label style={{ margin: "0 8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="Rival"
            checked={selectedFastener === "Rival"}
            onChange={() => handleInputChange("Rival")}
            style={{ marginRight: "10px" }}
          />
          Rival
        </label>
      </h4>
    </div>
  );
}

export default FastenerSelection;
