import { useState } from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";
import { useLocation } from "react-router-dom";
import { colorList } from "../../utils/formats";

function GutterTypeSelection() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const { previewData,  setFormData } = usePreviewStore();
  const selectedGutterType = previewData.gutter_type;
  const handleInputChange = (value) => {
    setFormData({ 
      ...previewData,
      gutter_type: value
     });
  };
  const handleInputChangeInput =(e) => {
    setFormData({ 
      ...previewData,
      color: e.target.value
     });
  };

  console.log(previewData)
  return (
    <div className="k-d-grid k-grid-cols-5 k-mt-10 k-pr-4">
      <h4 className="k-m-0 k-font-light k-pt-2">Gutter Type:</h4>
      
      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
          style={{ marginRight: "10px" }}
          type="radio"
          name="gutterType"
          value="FS"
          checked={selectedGutterType === "FS"}
          onChange={() => handleInputChange("FS")}
        />
        FS
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
          style={{ marginRight: "10px" }}
          type="radio"
          name="gutterType"
          value="k-Style"
          checked={selectedGutterType === "k-Style"}
          onChange={() => handleInputChange("k-Style")}
        />
        k-Style
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
          style={{ marginRight: "10px" }}
          type="radio"
          name="gutterType"
          value="HR"
          checked={selectedGutterType === "HR"}
          onChange={() => handleInputChange("HR")}
        />
        HR
      </label>

      <div className="k-display-flex k-gap-2">
        <h4 className="k-m-0 k-font-light k-pt-2">Color:</h4>
        <select value={previewData.color} onChange={handleInputChangeInput} disabled={isDisabled} style={{width:"100%",height:"30px",borderRadius:"5px"}} id="colorSelect" name="color">
        {
          colorList.map((col)=>(
            <option value={col}>{col}</option>
          ))
           }
        </select>
        {/* <div className="k-pos-relative k-top-1">
          <Input value={previewData.color} onChange={handleInputChangeInput('color')}/>
        </div> */}
      </div>
    </div>
  );
}

export default GutterTypeSelection;
