import { useState } from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";
import { useLocation } from "react-router-dom";

function Wood() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const { previewData, setFormData } = usePreviewStore();
  const selectedSize = previewData.soffit_other;
  const handleInputChange = (value) => {
    setFormData({
      ...previewData,
      soffit_other: value,
    });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };
  return (
    <div className="k-d-flex k-grid-cols-1">
      {/* <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-16">
        Size:
      </h4> */}

      <div className="k-d-flex k-gap-2">
        <label className="k-font-light k-m-0">
          <input
          disabled={isDisabled}
            style={{ marginRight: "10px" }}
            type="radio"
            name="wood-type"
            value="Wood"
            checked={selectedSize === "Wood"}
            onChange={() => handleInputChange("Wood")}
          />
         Wood 
        </label>

        <label className="k-font-light  k-m-0">
          <input
          disabled={isDisabled}
            style={{ marginRight: "10px" }}
            type="radio"
            name="wood-type"
            value="Vinyl"
            checked={selectedSize === "Vinyl"}
            onChange={() => handleInputChange("Vinyl")}
          />
          Vinyl 
        </label>

        <label className="k-font-light  k-m-0">
          <input
          disabled={isDisabled}
            style={{ marginRight: "10px" }}
            type="radio"
            name="wood-type"
            value="Other"
            checked={selectedSize === "Other"}
            onChange={() => handleInputChange("Other")}
          />
          Other
        </label>

        {selectedSize === "Other" && (
          <div className="k-d-flex k-m-0 k-w-30 k-font-light k-mt-0">
            <Input
              value={previewData.soffit_other_value}
              onChange={handleInputChange2("soffit_other_value")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Wood;
