import { Button } from '@progress/kendo-react-buttons'
import React, { useEffect, useState } from 'react'
import HomeTabsContent from './home-tabs-content'
import { HOST_API } from '../../config-global';
import axiosInstance, { endpoints } from '../../utils/axios';

const HomeTabs = () => {
  const [leads, setLeads] = useState();

  useEffect(() => {
    getAllLeads();
  }, []);
  const getAllLeads = async () => {
    try {
      const response = await axiosInstance.get(endpoints.leads.get_all_leads); // Adjust API endpoint
      if (response.data) {
        setLeads(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching :', error);
    }
  };

  return (
    <div className='k-mt-5'>
      <Button
        primary={true}
        className=""
        size="large"
      >
        Previous Day
      </Button>
      <Button
        primary={true}
        className="k-button-red k-mx-2"
        size="large"
      >
        Today
      </Button>
      <Button
        primary={true}
        className=""
        size="large"
      >
        Tomorrow
      </Button>
      <HomeTabsContent leads={leads} />
    </div>
  )
}

export default HomeTabs