import { useState } from "react";
import Input from "../../components/preview-input/input";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function LadderSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const selectedLadder = orderData.ladders;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      ladders: value
     });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value
    });
  };
  return (
    <div className="k-display-flex k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">
        Ladders:&nbsp;&nbsp;&nbsp;
        <label style={{ marginRight: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="ladder"
            value="8"
            checked={selectedLadder === "8"}
            onChange={() => handleInputChange("8")}
            style={{ marginRight: "10px" }}
          />
          8
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="ladder"
            value="10"
            checked={selectedLadder === "10"}
            onChange={() => handleInputChange("10")}
            style={{ marginRight: "10px" }}
          />
          10
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="ladder"
            value="24"
            checked={selectedLadder === "24"}
            onChange={() => handleInputChange("24")}
            style={{ marginRight: "10px" }}
          />
          24
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="ladder"
            value="28"
            checked={selectedLadder === "28"}
            onChange={() => handleInputChange("28")}
            style={{ marginRight: "10px" }}
          />
          28
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="ladder"
            value="32"
            checked={selectedLadder === "32"}
            onChange={() => handleInputChange("32")}
            style={{ marginRight: "10px" }}
          />
          32
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="ladder"
            value="40"
            checked={selectedLadder === "40"}
            onChange={() => handleInputChange("40")}
            style={{ marginRight: "10px" }}
          />
          40
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="ladder"
            value="other"
            checked={selectedLadder === "Other"}
            onChange={() => handleInputChange("Other")}
            style={{ marginRight: "10px" }}
          />
          Other
        </label>
      </h4>

      {selectedLadder === "Other" && (
        <div className="k-pos-relative k-top-1 k-ml-4" style={{ width: "100px" }}>
          <Input  value={orderData.laddersValue} onChange={handleInputChange2('laddersValue')}/>
        </div>
      )}
    </div>
  );
}

export default LadderSelection;
