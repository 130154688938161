import React from "react";
import map from "../../assets/images/map.png";
import phone from '../../assets/images/phone.png'
import { formatDate } from './../../utils/formats';
const SingleLeadTabContentCard = ({leadDetail}) => {
  const { time, name, address, email, notes, leadId, code } = leadDetail || {};

  return (
    <div
      className="k-rounded-lg k-elevation-1 k-p-4 k-mb-4 k-cursor-pointer"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <h2>{formatDate(time)}</h2>
      <h1 className="k-m-0 k-pb-2 k-font-normal k-text-uppercase">{name}</h1>
      <div className="k-mt-4 k-display-flex">
        <div className="k-mr-6">
          <img alt="map" src={map} />
        </div>
        <div>
          <h2
            style={{ lineHeight: "30px", color: "#337AB7" }}
            className="k-m-0 k-font-normal k-text-uppercase"
          >
            {address}
          </h2>
        </div>
      </div>
      <div className="k-mt-10 k-display-flex">
        <div className="k-mr-6">
          <img alt="phone" src={phone} />
        </div>
        <div>
          <h2
            style={{ lineHeight: "30px", color: "#337AB7" }}
            className="k-m-0 k-font-normal k-pt-1"
          >
            9089435102
          </h2>
        </div>
      </div>
      <div className="k-ml-6 k-mb-12">
        <h4 className="k-text-uppercase">Id: {leadId}</h4>
      <h4 className="k-text-uppercase">Mkt Code: {code}</h4>
        <h4 className="k-text-uppercase">Email Address: {email}</h4>
        <h4 className="k-text-uppercase">Calendar Notes: {notes}</h4>
      </div>
    </div>
  );
};

export default SingleLeadTabContentCard;
