// import { create } from 'zustand';
// import { previewValues } from './utils/preview-values';

// const usePreviewStore = create((set) => ({
//   previewData: previewValues,
//   setFormData: (newFormData) => set((state) => ({
//     previewData: { ...state.previewData, ...newFormData }
//   })),
//   reset: () => set(previewValues)
// }));

// export default usePreviewStore;

import { create } from 'zustand';
import { previewValues } from './utils/preview-values';

const usePreviewStore = create((set) => ({
  previewData: { ...previewValues }, // Initialize with a shallow copy
  setFormData: (newFormData) => 
    set((state) => ({
      previewData: { ...state.previewData, ...newFormData },
    })),
  reset: () =>
    set(() => ({
      previewData: JSON.parse(JSON.stringify(previewValues)), // Deep copy
    })),
}));

export default usePreviewStore;

