import React from 'react'
import Input from '../../components/preview-input/input'
import usePreviewStore from '../../store/preview-store';
import { useLocation } from 'react-router-dom';
import { colorList } from '../../utils/formats';

const DownspoutDetails = () => {
  const { previewData, setFormData } = usePreviewStore();
  console.log(previewData,"sajdfhsajkfdhsajk")
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
    const handleInputChange = (field) => (e) => {
        setFormData({
          ...previewData,
          [field]: e.target.value
        });
      };
  return (
    <div>
          <div className="k-d-grid k-grid-cols-2 k-mt-2 k-pr-4">
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2">Downspout Color:</h4>
        <div className="k-pos-relative k-top-1 k-right-6">
          {/* <Input value={previewData.downspout_color} onChange={handleInputChange('downspout_color')} /> */}
          <select value={previewData.downspout_color} onChange={handleInputChange('downspout_color')} disabled={isDisabled} style={{width:"100%",height:"30px",borderRadius:"5px"}} id="colorSelect" name="downspout_color">
          {
          colorList.map((col)=>(
            <option value={col}>{col}</option>
          ))
           }
        </select>
        </div>
      </div>
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2">Downspout Footage</h4>
        <div className="k-pos-relative k-top-1">
          <Input type='number' value={previewData.downspout_footage} onChange={handleInputChange('downspout_footage')} />
        </div>
      </div>
    </div>
    <div className="k-d-grid k-grid-cols-2 k-mt-2 k-pr-4">
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2"># of a elbows (1=1ft):</h4>
        <div className="k-pos-relative k-top-1 k-right-2">
          <Input value={previewData.aelbows} onChange={handleInputChange('aelbows')} />
        </div>
      </div>
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2"># of B elbows:</h4>
        <div className="k-pos-relative k-top-1">
          <Input value={previewData.belbows} onChange={handleInputChange('belbows')} />
        </div>
      </div>
    </div>
    <div className="k-d-grid k-grid-cols-2 k-mt-2 k-pr-4">
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2"># of Round elbows:</h4>
        <div className="k-pos-relative k-top-1 k-right-6">
          <Input value={previewData.roundElbows} onChange={handleInputChange('roundElbows')} />
        </div>
      </div>
      <div className="k-d-grid k-grid-cols-2">
        <h4 className="k-m-0 k-font-light k-pt-2"># of Offset elbows:</h4>
        <div className="k-pos-relative k-top-1">
          <Input value={previewData.offsetElbows} onChange={handleInputChange('offsetElbows')} />
        </div>
      </div>
    </div>

    </div>
  )
}

export default DownspoutDetails