import React, { useEffect, useState } from "react";
import SingleLeadTabContentCard from "./single-lead-tab-content-card";
import SingleLeadDetailCard from "./single-lead-detail-card";
import ActionsBar from "../../components/actions-bar/actions-bar";
import ResultLeadCard from "./result-lead-card";
import DocumentUpload from "./document-upload";
import DemoCard from "./demp-card";
import { useSearchParams } from "react-router-dom";
import useResultStore from "../../store/result-store";

const SingleLeadTabContent = ({ leadDetail }) => {
  const [searchParams] = useSearchParams();
  const { resultData, setFormData } = useResultStore();

  const [enableEdit, setEnableEdit] = useState(false);
  useEffect(() => {
    setEnableEdit(leadDetail?.resulted);
  }, [leadDetail]);

  return (
    <div style={{ backgroundColor: "#FFFFFF", border: "1px solid #d3d3d3" }}>
      <div
        className="k-rounded-md k-pt-4 k-px-4 k-pb-1 k-m-2"
        style={{ backgroundColor: "#8ABD5A" }}
      >
        <SingleLeadTabContentCard leadDetail={leadDetail}/>
        <ResultLeadCard
          enableEdit={enableEdit || resultData.resultType=='Demo'}
          update={(value) => setEnableEdit(value)}
        />
        {(enableEdit || resultData.resultType=='Demo') && <DemoCard 
        leadDetail={leadDetail}
        isView={enableEdit}
        />}
        <SingleLeadDetailCard />
        {(enableEdit || resultData.resultType=='Demo' )&& <DocumentUpload />}
      </div>
      <div
        className="k-p-8 k-mt-8 k-text-center"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <h2 className="k-text-uppercase k-font-normal">
          Finish making your changes to continue
        </h2>
      </div>
    </div>
  );
};

export default SingleLeadTabContent;
