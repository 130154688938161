import { useState } from "react";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function PartialSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const isPartial = orderData.partial;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      partial: value
     });
  };
  return (
    <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
      (Partial: &nbsp;&nbsp;
      <label>
        <input
        disabled={isDisabled}
          type="radio"
          name="partial"
          value="Yes"
          checked={isPartial === "Yes"}
          onChange={() => handleInputChange("Yes")}
          style={{ marginRight: "4px" }}
        />
        Yes
      </label>
      <label style={{ marginLeft: "8px" }}>
        <input
        disabled={isDisabled}
          type="radio"
          name="partial"
          value="No"
          checked={isPartial === "No"}
          onChange={() => handleInputChange("No")}
          style={{ marginRight: "4px" }}
        />
        No
      </label>
      )
    </h4>
  );
}

export default PartialSelection;
