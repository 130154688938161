
// components
import { AuthContext } from './auth-context';
import SplashScreen from '../../components/loading-screen/splash-screen';

// ----------------------------------------------------------------------

export function AuthConsumer({ children }) {
  return (
    <AuthContext.Consumer>
      {(auth) => {
        console.log(auth);
        return auth.loading ? <SplashScreen /> : children;
      }}
    </AuthContext.Consumer>
  );
}

