import React from 'react'
import drawingfour from '../../assets/images/drawing-four.png';
import drawingone from '../../assets/images/drawing-one.png';
import drawingthree from '../../assets/images/drawing-three.png';
import drawingtwo from '../../assets/images/drawing-two.png';
import Drawing from '../preview/drawing';
import useOrderStore from '../../store/order-store';
const DrawingBox = () => {
  const { orderData, setFormData } = useOrderStore();
  const updater=(data)=>{
    setFormData({
      ...orderData,
      files: data,
    });
  }
  return (
    <div className="k-pl-6 k-pb-4 k-pt-2 k-display-flex" style={{flexDirection:"column" , justifyContent:"space-between"}}>

    <div>
      <div className="k-text-center">
        <h2>
          <span style={{ borderBottom: "2px solid black" }}>Attachments</span>
        </h2>
      </div>
      <Drawing updater={updater}/>

    </div>
  </div>
  )
}

export default DrawingBox