import React from 'react'
import HomeTabContentCard from './home-tab-content-card'

const HomeTabsContent = ({ leads }) => {
console.log("🚀 ~ HomeTabsContent ~ leads :", leads )

  return (
    <div className='k-rounded-md k-pt-4 k-px-4 k-pb-1 k-mb-4' style={{backgroundColor:"#8ABD5A"}}>
      {leads?.map((item)=><HomeTabContentCard item={item}/>) }
    </div>
  )
}

export default HomeTabsContent