import React from 'react';
import Input from '../../components/preview-input/input';
import usePreviewStore from '../../store/preview-store';
import { useLocation } from 'react-router-dom';

const MainDetails = ({id}) => {
  const { previewData, setFormData } = usePreviewStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const leadId = queryParams.get('leadId');
  const handleInputChange = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value
    });
  };


  return (
    <div className='k-d-grid k-grid-cols-4 k-text-center k-gap-5 k-pt-4 k-pb-1 k-px-2'>
      <div className='k-display-flex k-gap-2'>
        <h2 className='k-m-0'>Customer</h2>
        <div className='k-pos-relative k-top-1'>
          <Input value={previewData.customer} onChange={handleInputChange('customer')} />
        </div>
      </div>
      <div className='k-display-flex k-gap-2'>
        <h2 className='k-m-0'>City</h2>
        <div className='k-pos-relative k-top-1 k-w-full'>
          <Input value={previewData.city} onChange={handleInputChange('city')} />
        </div>
      </div>
      <div className='k-display-flex k-gap-2'>
        <h2 className='k-m-0'>ST</h2>
        <div className='k-pos-relative k-top-1 k-w-full'>
          <Input value={previewData.st} onChange={handleInputChange('st')} />
        </div>
      </div>
      <div className='k-display-flex k-gap-2'>
        <h2 className='k-m-0'>Job#</h2>
        <div className='k-pos-relative k-top-1 k-w-full'>
          <Input disable={true} value={leadId}  />
        </div>
      </div>
    </div>
  );
};

export default MainDetails;
